@import './variables';

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.second-level {
    // padding-left: 10px;

    li {
        padding-left: 5px;
        width: 100%;
    }
}

.third-level {
    padding-left: 20px;

    li {
        width: 100%;
    }
}

.menu-title {
    margin-top: 5px;
}

.sidebar-collapsed {
    -webkit-animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    .profile-element {
        width: 100px;
        height: 100px;
        padding: 8px;
        margin-left: 15%;
        margin-bottom: 25px;

        -webkit-animation: scale-out-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: scale-out-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .company-info {
        display: none;
        white-space: nowrap;
        -webkit-animation: fade-out 0.2s ease-out both;
                animation: fade-out 0.2s ease-out both;
    }

    .menu-title {
        -webkit-animation: fade-out 0.2s ease-out both;
                animation: fade-out 0.2s ease-out both;
    }

    .second-level, .third-level {
        display: none;
    }

    .notification-bell-container {
        position: absolute;
        margin-bottom: 100px;
        right: 32px;
        animation: 1s fade-in;
    }
}

.company-info, .menu-title {
    -webkit-animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.sidebar-opened {
    -webkit-animation: slide-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 15px 36px 15px 34px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    color: white;
    display: flex;
    align-items: center;
}

.avatar {
    display: flex;
    align-items: center;
}

#sidebar ul .nav-subitem a {
    padding: 7px 36px 7px 34px;
}

.without-icon {
    padding-left: 74px !important;
}

a[data-toggle="collapse"] {
    position: relative;
}

#sidebar {

    a[aria-expanded="true"].dropdown-toggle::after {
        display: none;
    }

    a[aria-expanded="true"].dropdown-toggle::before {
        display: block;
        position: absolute;
        top: 50%;
        right: 27px;
        transform: translateY(-50%) rotate(268deg);
        color: white;
        font-family: 'Material Icons', serif;
        font-size: 10px;
        content: "\e5e0";
    }

    a[aria-expanded="false"].dropdown-toggle::after {
        display: none;
    }

    a[aria-expanded="false"].dropdown-toggle::before {
        display: block;
        position: absolute;
        top: 50%;
        right: 27px;
        transform: translateY(-50%);
        color: white;
        font-family: 'Material Icons', serif;
        font-size: 10px;
        content: "\e5e0";
    }

    .nav-link {
        display: flex;
        align-items: center;

        i {
            color: #fff;
        }
    }

}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
}

.notification-bell-container {
    position: absolute;
    margin-top: 1px;
    right: 34px;
}

.sidebar {
    border-right: 1px solid #D6D6D6;
    background: rgb(15, 41, 118);
    background: linear-gradient(0deg, rgba(15, 41, 118, 1) 0%, rgba(0, 212, 255, 1) 100%);
    opacity: 1;
    min-height: 100%;
    position: fixed;
    z-index: 9999;
}

.collapse-button {
    width: 100%;
    padding-right: 25px;
    margin-bottom: -26px;

    a {
        float: right;
        font-size: 27px;
        color: #fff;
    }
}

.icon-edit-company {
    color: black;
    font-size: 15px;
    background: white;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
}

.profile-element, .mini-profile-element {
    vertical-align: middle;
    border: 3px solid #f1EDED;
    border-radius: 50%;
    background: #3ba4d8;
    opacity: 1;
    animation: fade 0.2s linear;
}

.profile-element {
    width: 133px;
    height: 125px;
    padding: 20px;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.mini-profile-element {
    width: 50px;
    height: 50px;
    margin-left: -6px;
    padding: 5px;
}

@keyframes fade {
    0%, 50% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.label-user {
    padding-top: 14px;
    font-size: 23px;
    font-weight: bold;
    color: #fff;
    margin: auto;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-email {
    font-size: 15px;
    color: #fff;
}

.minimalize-styl-2 {
    padding: 4px 12px;
    margin: 14px 5px 5px 20px;
    font-size: 14px;
    float: left;
}

.btn-toggle {
    background-color: transparent;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}

.top-navbar {
    border: 1px solid #EAECEF;
    background-color: #fff;
    max-height: 75px;
}

.nav-item {
    cursor: pointer;
}

.margin-avatar {
    margin-top: 30px;
}

.margin-user {
    margin-top: 8px;
}

#container-item span {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    padding-left: 2px;
    color: #fff;
}

.icon-menu {
    width: 17px;
    margin-right: 12px;
    margin-top: -6px;
}

.menu-item {
    display: block;
    width: 100%;
    min-width: 87%;
    white-space: nowrap;
    animation: 1s fade-in;
}

.menu-item:hover {
    cursor: pointer;
    background: rgba(0, 182, 255, 0.4);
}

li:hover {
    background: rgba(255, 255, 255, 0.045);
}

.mini-navbar .menu-item {
    padding: 5px 20px 5px 20px !important;
    padding-left: 11px !important;
    min-width: 50%;
}

.mini-navbar .nav-third-level {
    padding-left: 60px;
}

#container-item li:focus {
    outline: none !important;
}

#container-item .active {
    transition: border-left .2s;
    border-left: 8px solid #6cfff4;
    background: rgba(0, 182, 255, 0.4);
    color: #6cfff4;

    .dropdown-toggle::after {
        color: #6cfff4;
    }

    a {
        padding-left: 28px;
    }

    span {
        color: #6cfff4;

        img {
            filter: invert(90%) sepia(78%) saturate(6275%) hue-rotate(151deg) brightness(102%) contrast(100%);
        }
    }
}

.active-link > a {
    color: #6cfff4 !important;
}

.side-menu-icon {
    margin-top: -4px;
    width: 20px;
    height: 20px;
    filter: invert(99%) sepia(0%) saturate(110%) hue-rotate(193deg) brightness(116%) contrast(100%);
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 1900px) {
    .label-user {
        max-width: 250px;
    }
}

@media (max-width: 980px) {
    .label-user {
        max-width: 220px;
    }
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    .label-user {
        max-width: 100px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}

#sidebar {
    overflow-y: auto;
    max-height: 100%;
    overflow-x: hidden;
    z-index: 1001;
}

.btn-close {
    // position: absolute;
    // bottom: 0;
    z-index: 999;
}

::-webkit-scrollbar {
    background: transparent;
    width: 4px;
}


.home-version-tag {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
