.animate-notification {
    animation: 1.5s bell-vibrate ease-in-out infinite;
    -webkit-animation: 1.5s bell-vibrate ease-in-out infinite;
    -moz-animation: 1.5s bell-vibrate ease-in-out infinite;
    -o-animation: 1.5s bell-vibrate ease-in-out infinite;
}

.animate-grow-circle {
    animation: grow-initial 0.1s 0s, grow-final 0.5s 0.1s;
    -webkit-animation: grow-initial 0.1s 0s, grow-final 0.5s 0.1s;
    -moz-animation: grow-initial 0.1s 0s, grow-final 0.5s 0.1s;
    -o-animation: grow-initial 0.1s 0s, grow-final 0.5s 0.1s;
}

.fade-in {
    animation: 1s fade-in;
}

.fade-out {
    animation: 1s fade-out;
}

@keyframes pulse-yellow {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}

@keyframes pulse-red {
    0% {
        transform: scale(0.85);
        box-shadow: 0 0 0 0 rgba(255, 68, 68, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(7, 96, 239, 0);
    }

    100% {
        transform: scale(0.85);
        box-shadow: 0 0 0 0 rgba(7, 96, 239, 0);
    }
}

@-webkit-keyframes bell-vibrate {
    0% {
        transform: rotate(0);
    }
    4% {
        transform: rotate(8deg);
    }
    8% {
        transform: rotate(-8deg);
    }
    12% {
        transform: rotate(8deg);
    }
    16% {
        transform: rotate(-8deg);
    }
    20% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@-moz-keyframes bell-vibrate {
    0% {
        transform: rotate(0);
    }
    4% {
        transform: rotate(8deg);
    }
    8% {
        transform: rotate(-8deg);
    }
    12% {
        transform: rotate(8deg);
    }
    16% {
        transform: rotate(-8deg);
    }
    20% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@-o-keyframes bell-vibrate {
    0% {
        transform: rotate(0);
    }
    4% {
        transform: rotate(8deg);
    }
    8% {
        transform: rotate(-8deg);
    }
    12% {
        transform: rotate(8deg);
    }
    16% {
        transform: rotate(-8deg);
    }
    20% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes bell-vibrate {
    0% {
        transform: rotate(0);
    }
    4% {
        transform: rotate(8deg);
    }
    8% {
        transform: rotate(-8deg);
    }
    12% {
        transform: rotate(8deg);
    }
    16% {
        transform: rotate(-8deg);
    }
    20% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}


@-webkit-keyframes width-panel {
    0% {
        width: inherit;
    }
    100% {
        width: 100%;
    }
}

@-moz-keyframes width-panel {
    0% {
        width: inherit;
    }
    100% {
        width: 100%;
    }
}

@-o-keyframes width-panel {
    0% {
        width: inherit;
    }
    100% {
        width: 100%;
    }
}

@keyframes width-panel {
    0% {
        width: inherit;
    }
    100% {
        width: 100%;
    }
}

@-webkit-keyframes grow-initial {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}

@-webkit-keyframes grow-final {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


@-moz-keyframes grow-initial {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}

@-moz-keyframes grow-final {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@-o-keyframes grow-initial {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}

@-o-keyframes grow-final {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


@keyframes grow-initial {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes grow-final {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes slide-right {
    0% {
        width: $sidebar-min-width;
    }
    100% {
        width: $sidebar-width;
    }
}
@keyframes slide-right {
    0% {
        width: $sidebar-min-width;
    }
    100% {
        width: $sidebar-width;
    }
}

@-webkit-keyframes slide-left {
    0% {
        width: $sidebar-width;
    }
    100% {
        width: $sidebar-min-width;
    }
}
@keyframes slide-left {
    0% {
        width: $sidebar-width;
    }
    100% {
        width: $sidebar-min-width;
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 1;
    }
}
@keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 1;
    }
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}
@keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}


@keyframes pulse {
    0% {
        transform: scale(1.2);
    }

    70% {
        transform: scale(1.8);
    }

    100% {
        transform: scale(1.2);
    }
}