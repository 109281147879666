// STEPPER
.mat-step-label {
    font-size: 14px;
    font-weight: 400
}

.mat-step-sub-label-error {
    font-weight: normal
}

.mat-step-label-error {
    font-size: 14px
}

.mat-step-label-selected {
    font-size: 14px;
    font-weight: 500
}


.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
    background-color: rgba(0, 0, 0, .04)
}

@media(hover: none) {
    .mat-step-header:hover {
        background: none
    }
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
    color: rgba(0, 0, 0, .54)
}

.mat-step-header .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
    color: #fff
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: #3f51b5;
    color: #fff
}

.mat-step-header .mat-step-icon-state-error {
    background-color: transparent;
    color: #f44336
}

.mat-step-header .mat-step-label.mat-step-label-active {
    color: rgba(0, 0, 0, .87)
}

.mat-step-header .mat-step-label.mat-step-label-error {
    color: #f44336
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: #fff
}

.mat-stepper-vertical-line::before {
    border-left-color: rgba(0, 0, 0, .12)
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
    border-top-color: rgba(0, 0, 0, .12)
}

.mat-horizontal-stepper-header {
    height: 72px
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
    padding: 24px 24px
}

.mat-stepper-vertical-line::before {
    top: -16px;
    bottom: -16px
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
    top: 36px
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 36px
}

.mat-vertical-content-container {
    margin-left: 43px !important;
}

// MatStepper
.mat-step-icon-selected {
    width: 40px !important;
    height: 40px !important;

    span {
        font-weight: bold;
        font-size: 19px;
    }
}

.mat-step-label-selected {
    font-weight: bold !important;
}
