@import 'src/assets/scss/variables';

.checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    padding-bottom: 8px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: -1;
    left: 0;
    height: 26px;
    width: 26px;
    border: 1px solid grey;
    border-radius: 3px;
}

.checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

.checkbox input:checked ~ .checkmark {
    background-color: $bg-checked-checkbox;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
