
.switch-medium {
    height: 22px;
    margin-top: 10px;
    outline: none;
}

.switch-medium small {
    width: 22px;
    height: 22px;
    left: 0;
    outline: none;
}

.switch-medium.checked small {
    left: 29px;
}

