@import './variables';

.container {
    background: none;
}

// GENERAL
body {
    font-family: Mulish, sans-serif;
    overflow-x: hidden;
}

.gradient-background {
    background: rgb(15, 41, 118);
    background: linear-gradient(0deg, rgba(15, 41, 118, 1) 0%, rgba(0, 212, 255, 1) 100%);
}

.cdk-overlay-container {
    z-index: $zindex-modal-backdrop;
}

.icon-input {
    display: block;
    position: absolute;
    margin-top: 10px;
    margin-left: 15px;
    font-size: 23px;
    color: #FFFFFF;
}

.icon {
    display: inline-flex;
    color: $icon-grey;
}

.warning-icon {
    cursor: default;
    color: $warning-color;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// TABLES
.table-vmiddle td, .table-vmiddle th {
    vertical-align: middle;
    border-top: 1px solid #e4e6f9;
}

// BADGE
.badge {
    line-height: 2;
    text-transform: capitalize;
}

.badge-warning {
    background-color: $warning-color
}

.content-header {
    position: relative;
    height: 70px;
}

// GENERAL CONTENT
.content {
    width: calc(100vw - #{$sidebar-width});
    min-height: 100%;
    float: right;
    padding: 0 0 20px 0;
}

.content-opened {
    width: calc(100vw - #{$sidebar-min-width} - #{$sidebar-scroll});
}

.content-collapsed {
    width: calc(100vw - #{$sidebar-width} - #{$sidebar-scroll});
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group-prepend {
    position: absolute;
    right: 0;
    z-index: 9;
    height: 100%;
    cursor: pointer;
    margin-right: 0px !important;
}

.input-group-text {
    background-color: #fff;
    color: #105497;
    padding: .375rem .6rem;
    transition: all .3s ease-in-out;
}

.input-group-text.has-error {
    background-color: #fff !important;
    color: rgba(red, .7);
    border-color: #DC3545;
}

.menu-title {
    width: 100%;
}

.menu-title:focus {
    outline: none;
}

// PAGES
.page-title {
    font-size: 1.8rem;
    text-transform: uppercase;
    color: $title-color;
    font-weight: bold;
}

.page-description {
    font-family: Mulish, sans-serif;
    font-size: 15px;
    color: black;
}

.scroll {
    overflow-y: auto;
    height: 37rem;
    display: block;
}

.app-table {
    overflow: visible !important;
}

// DATA TABLE SORT HEADER
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    top: 30%;
    content: "";
    width: 12px;
    height: 8px;
    right: 0;
    background: url("/assets/icons/sort-arrow-up.svg") no-repeat;
    font-size: 2rem;
}


table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    top: 60%;
    content: "";
    width: 12px;
    height: 8px;
    right: 0;
    background: url("/assets/icons/sort-arrow-down.svg") no-repeat;
    font-size: 2rem;
}

// ANGULAR MATERIAL EXPANSION PANEL

table {
    font-family: Mulish, sans-serif;
}

.load-more {
    margin: 30px auto 0 auto;
}

.load-more:hover {
    text-decoration: underline;
}

.side-nav-right-container {
    height: 100vh;
}

.mat-drawer {
    width: 390px;
    background-color: white;
}

.mat-drawer-content {
    background: white;
}

.mat-drawer-shown {
    background-color: rgba(0, 0, 0, .6) !important;
}

input.form-control {
    padding: .375rem 1.6rem .375rem .75rem;
}

input {
    border-color: #bab8b8;
}

input[type=date].form-control {
    padding: .375rem .75rem;
}

input.form-control.ng-invalid.ng-touched {
    border-color: #DC3545;
}

.input-group-text .disabled {
    background-color: #e9ecef !important;
}

.mat-tooltip {
    font-size: 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background: white;
    color: #2286DD;
    text-transform: uppercase;
}

.nav-tabs .nav-link.active {
    border-color: transparent;
    background: #0089FF;
    color: white;

    i {
        font-weight: bold;
    }

    span {
        font-weight: bold;
    }
}

.nav-pills .nav-link {
    border-radius: 0;
    color: #2286DD;
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100%;

    i {
        margin-right: 1rem;
    }
}

.relative {
    position: relative;
}

.nav-link {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
}

.cdk-global-scrollblock {
    position: inherit !important;
    overflow: auto;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav-tabs .nav-link {
    i, span {
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.nav-tabs {
    margin-left: -15px;
    margin-right: -15px;
}

.disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
}


.page-title-button {
    position: absolute;
    right: 0;
    min-width: 200px;
}

.back-to-list-button {
    position: absolute;
    left: 0;
    cursor: pointer;
    z-index: 999;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #ebf2f8;
    padding-left: 5px;
}

::-webkit-scrollbar-thumb:horizontal, ::-webkit-scrollbar-thumb:vertical {
    background: #c1c1c1;
    border-radius: 10px;
}

::-webkit-scrollbar-track:horizontal {
    width: 50px;
}

.warning-icon {
    font-size: 21px;
    text-align: center;
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s; /* Firefox */
    -webkit-animation: fadein 0.5s; /* Safari and Chrome */
    -o-animation: fadein 0.5s; /* Opera */
}

.internal-text-cookie {
    color: $light-blue-2;
    font-weight: bold;
}

.internal-text-cookie {
    color: $light-blue-2;
    font-weight: bold;

    &:hover {
        color: $light-blue-2;
    }

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0;
        border: 0 none;
        color: $light-blue-2;

    }
}

.mat-checkbox-inner-container {
    height: 26px !important;
    width: 26px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox-indeterminate.mat-accent
.mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: $title-color !important;
}

.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $custom-grey-color !important;
}

.mat-checkbox-frame {
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
}

.page-link {
    color: $title-color !important;
    font-weight: 600 !important;
    cursor: pointer !important;
}

.page-item.active .page-link {
    color: white !important;
    background-color: $title-color;
    border-color: $title-color;
}

.password-group {
    width: 100%;

    .input-group-append {
        position: absolute;
        right: 0;
        margin-top: 12px;
        margin-right: 5px;

        .input-group-text {
            background: transparent;
            border: none;
            color: white;

            i {
                font-size: 19px;
                cursor: default;
            }
        }
    }
}

.password-nav-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
}

.ng-select.ng-select-opened .ng-select-container {
    z-index: 500 !important;
}

.ng-select .ng-dropdown-panel {
    z-index: 500 !important;
}

::placeholder {
    color: #70707085 !important;
    opacity: 0.10;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #70707085 !important;
    opacity: 0.10;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #70707085 !important;
    opacity: 0.10;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
    color: #333;
    display: inline-block;
    font-size: .85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
}


@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein { /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein { /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.toast-info {
    background-color: #0760ef !important;
}

.toast-success {
    background-color: #0760ef !important;
}

.close {
    font-size: 2rem;
    cursor: pointer;
    width: 0;
    position: relative;
    right: 8%;
    bottom: 10px;
    z-index: 999;
}

input[type=radio] {
    box-sizing: border-box;
    cursor: pointer;
}

.tooltip-pewter {
    --balloon-color: #a5b3cb;
}

.tooltip-pewter-disabled {
    --balloon-color: #40464e;
}

[aria-label] {
    &[data-balloon-pos] {
        &:after {
            z-index: $zindex-tooltip !important;
            line-height: 1rem;
            text-align: center;
        }

        &:before {
            z-index: $zindex-tooltip !important;
        }
    }
}

.tooltip-text {
    --balloon-font-size: medium;
    --balloon-border-radius: 6px;
}

.pulse-warning {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: pulse-yellow 2s infinite;
}

.pulse-danger {
    background: rgba(255, 68, 68, 1);
    animation: pulse-red 2s infinite;
}

.pulse-info {
    background: rgba(7, 96, 239, 1);
    animation: pulse-red 2s infinite;
}

.pulse {
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1) !important;
    animation: pulse-yellow 2s infinite !important;
}

.bg-danger {
    background-color: $danger-color;
}

@media screen and (max-height: 900px) {
    .page-title-button {
        position: absolute;
        right: 0;
        min-width: 130px;
    }
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;

}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

input:disabled::-webkit-input-placeholder {
    opacity: 0;
}

input:disabled:-moz-placeholder {
    opacity: 0;
}

input:disabled::-moz-placeholder {
    opacity: 0;
}

input:disabled:-ms-input-placeholder {
    opacity: 0;
}

input::-webkit-input-placeholder {
    color: #b8b8b8 !important;
    opacity: 0.7 !important;
}

input:-moz-placeholder {
    color: #b8b8b8 !important;
    opacity: 0.6 !important;
}

input::-moz-placeholder {
    color: #b8b8b8 !important;
    opacity: 0.6 !important;
}

input:-ms-input-placeholder {
    color: #b8b8b8 !important;
    opacity: 0.6 !important;
}


i, button, a {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

app-speech-bubble-tab {
    width: auto;
    position: absolute;
    z-index: 99;
    right: -30px;
    top: -21px;
}

.accordion > .card {
    overflow: visible;
}


// MODALS
// TODO: Remove this when update all components to use the Base modal component

.circle-modal {
    background: #ffc107;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -90px;

    .edit-icon {
        font-size: 40px;
        color: white;
    }
}

.circle-historical-modal {
    background: #565656;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -90px;

    .schedule-icon {
        font-size: 40px;
        color: white;
    }
}

.full-modal > mat-dialog-container {
    padding: 24px 0 24px 0;
}

.curved-modal > mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
}

.curved-modal > mat-dialog-container:before {
    content: "";
    width: 100px;
    border-radius: 50%;
    height: 100px;
    display: block;
    margin: -47px auto 0;
    box-shadow: 0 0 0 3000px white;
}


.required-marker {
    color: $danger-color;
    font-weight: bold;
}

.ng-select .ng-select-container {
    min-height: 0;
}

.ng-select.ng-select-single .ng-select-container {
    height: calc(1.5em + 0.75rem + 2px);
}

.custom-badge-bookkeeping-keys {
    border-radius: 20px;
    color: white;
    line-height: 0;
    height: 30px;
    text-transform: capitalize;
    padding: 16px 15px 5px;
}

.custom-badge-view-mode {
    border-radius: 20px;
    color: white;
    line-height: 0;
    height: 25px;
    text-transform: capitalize;
    display: inline;
    padding: 13px 15px 5px;
}

.input-group-text {
    padding: 3px;
}

.text-underline {
    text-decoration: underline !important;
}

.btn:hover {
    filter: brightness(1.1);
}

.accordion {
    .collapse {
        box-shadow: 0 -5px 8px 7px rgba(168, 176, 219, 0.1607843137) !important;
    }
    .accordion-body {
        padding: 30px !important;
    }
}

.trade-association-popover {
    width: 30% !important;
    max-width: 30% !important;
}

.title-print-section {
    display: none;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        color-adjust: exact;
        padding: 50px !important;
    }

    .title-print-section {
        display: block;
    }

    .print-section {
        padding: 50px !important;
        text-align: justify;
    }
}
