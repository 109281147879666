// Basic Colors
$principal-color: #0760ef;
$secundary-color: #76f4fe;
$warning-color: #ffc107bf;
$warning-intense-color: #ffc107;
$title-color: #275fa1;
$link-color: #6CFFF4;
$success-color: #28a745;
$danger-color: #dc3545;
$danger-light-color: #f75262;
$custom-grey-color: #6c757d;
$blacked-blue: #13243b;
$blacked-blue-1: #2F4050;
$light-blue-2: #265ca0;
$light-blue-3: #67fefe;
$light-blue-4: #70EBDD;
$light-blue-5: #41BCD9;

$datev-color: #dc3545;
$lexware-color: #ffc107;

//Icon
$icon-grey: #5b6c80;

//Sidebar width
$sidebar-width: 400px;
$sidebar-opened: 320px;
$sidebar-min-width: 90px;
$sidebar-scroll: 4px;

//Navigation
$nav-bg: #2F4050;
$notification-nav-bg: #fffefe;
$general-title-color: #5B6D80;
$general-text-color: #8C969F;

//Border
$border-tabbed-table: #0089FF;

//Label for table
$span-table-highlight: #0B66F7;

//Checkbox
$bg-checked-checkbox: #0077FF;

//Tag colors
$tag-cornflower-blue-1: #697FDE;
$tag-cornflower-blue-2: #72A3FF;
$tag-ocean-blue-1: #41BCD9;
$tag-havelock-blue: #58ABE0;
$tag-medium-turquoise: #41BCD9;
$tag-mauvelous: #FC94A4;
$tag-pale-violet-red: #DE697B;
$tag-hit-pink: #FFA872;
$tag-sandy-brown: #FFC048;
$tag-medium-slate-blue: #7667EB;
$tag-shadow: #414E6C;
$tag-lights-late-gray: #7F7EA3;

//Page description
$page-description-color: #394551;

//Tabs
$tab-not-selected: #8F9CB4;
$tab-active: #1276FF;

//zindex sizes
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
