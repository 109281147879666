@import './variables';

@media (max-width: 1287px) {
    .pad-left-15 {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .pad-right-15 {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .pad-left-25 {
        padding-left: 15px !important;
    }
}

@media (min-width: 1288px) and (max-width: 1899px) {

    .app-table {
        margin: 0% !important;
        padding: 0% !important;
        // height: 20rem !important;
    }
    * {
        table {
            font-size: x-small !important;
            th {
                font-size: x-small !important;
            }
            .btn-action {
                // display: flex;
                width: 30px;
                height: 30px;
                font-size: 1.2rem;
                align-items: center;
                justify-content: center;
                align-content: center;
            }
            img {
                width: 20px;
                height: 20px;
            }
            * {
                .sorting :after, .sorting :before {
                    width: 11px;
                    height: 10px !important;
                    background-size: 9px !important;
                }
                span {
                    font-size: 0.6rem !important;
                }
            }

            .col-confirm {
                width: 30px !important;
                height: 30px !important;

                .fab {
                    width: 30px !important;
                    height: 30px !important;
                    font-size: larger !important;
                    line-height: 30px !important;
                }
            }

            .actions {
                display: flex;
                flex-direction: row;
            }

            .td-booking-keys {
                padding: 0.2rem 15px 0.2rem 1rem !important;
            }
        }
    }

    .pagination {
        * {
            font-size: 0.6rem !important;
        }
    }

    .top-title {
        margin: 2% 0% !important;
        padding: 0% !important;
    }

    .page-title {
        font-size: larger !important;
    }

    .search-input {
        font-size: small !important;
        width: 250px;
        padding: 5px 5px 5px 5px;
        i {
            font-size: initial;
        }
    }

    .rounded {
        font-size: smaller !important;
    }

    .nav-item {
        width: 0;
        span {
            font-size: x-small !important;
        }
        .side-menu-icon {
            width: 14px;
            height: 14px;
        }
    }

    .content {
        padding: 10px 0 20px 0
    }

    .content-opened {
        width: calc(100vw - #{$sidebar-min-width});
    }

    .content-collapsed {
        width: calc(100vw - #{$sidebar-width});
    }

    .sidebar-opened {
        .profile-element {
            width: 100px;
            height: 100px;
        }
    }

    .sidebar  {

        .company-info {
            * {
                font-size: 1rem !important;
            }
            .label-email {
                font-size: small !important;
            }
        }
        .menu-item {
            min-width: 100% !important;
            * {
                font-size: x-small !important;
            }
            .material-icons {
                font-size: 1rem !important;
            }
            .notification-bell-container {
                .custom-badge {
                    font-size: x-small !important;
                }
                .animate-notification {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .invite-link-dropdown-title, .invite-link-field {
        font-size: smaller !important;
    }

    .material-icons {
        font-size: larger !important;
    }

    .header {
        .user-info {
            display: flex !important;
            align-items: center;
            .user-image {
                width: 30px;
                height: 30px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                padding: 0px !important;
                img {
                    width: 15px;
                }
            }
            .dropdown {
                margin-top: 0px !important;
                * {
                    font-size: 0.7rem !important;
                }

                .btn-logout {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .open-tasks {
        max-height: 50vh;
        .card-task {
            .card-left-side {
                font-size: x-small !important;
            }

            .card-right-side {
                font-size: x-small !important;
            }

            .card-right-text {
                padding-bottom: 0% !important;
            }
        }
    }

    .header-collapsed {
        .page-description {
            padding-left: 0px !important;
        }
    }

    span, label, .page-description {
        font-size: small !important;
    }

    input, option, .ng-option, .ng-select, .mat-calendar-body, .mat-calendar-table-header, .mat-tooltip {
        font-size: x-small !important;
    }

    .form-text {
        font-size: x-small !important;
    }

    .switch-medium {
        height: 20px !important;
        min-width: 35px !important;
    }

    .switch-medium small {
        width: 19px !important;
        height: 19px !important;
    }

    .bubble-info-task-to-do {
        span {
            text-transform: uppercase;
            font-size: 9px;
            color: red;
            letter-spacing: 0;
            font-weight: normal;
        }
        i {
            font-size: 15px;
            color: red;
            font-weight: normal;
            padding-left: 2px;
            margin-left: 12px;
        }
    }
    .bubble-info-task-to-do:before {
        left: 22% !important;
    }

    .auth-full {
        .header {
            .logotipo-full {
                .header-icons {
                    img {
                        width: 35px;
                    }
                }
            }
        }

        .validator {
            .header {
                h1 {
                    font-size: medium;
                }
                h2 {
                    font-size: small;
                }
                margin-top: 0 !important;
            }
            .confirm-data {
                margin-top: 0 !important;
            }
        }

        .form-login {
            max-width: 300px;
            margin: 0 auto;

            img {
                width: 20px !important;
            }

            .form-control {
                padding: 25px 36px 25px 50px;
                font-size: x-small !important;
            }

            .input-group-text {
                height: 1.2rem;
            }

            .forgot-password {
                font-size: x-small !important;
            }
            .btn-login {
                margin-top: 1rem !important;
                .btn-submit {
                    padding: 8px 25px !important;
                }
            }
        }
    }

    .jumbotron {
        .search-off {
            font-size: medium !important;
            margin-left: 2%;
        }

        .display-4 {
            font-size: medium !important;
        }
    }

    app-toggle-drop {
        .current, .to-save, .expired, .future {
            font-size: x-small !important;
        }
    }

    .custom-drop {
        .confirm-toggle {
            min-width: 10px !important;
            font-size: small !important;
        }
    }
    .input-group-text {
        max-height: max-content;
    }

    .circle-modal {
        i {
            font-size: 35px !important;
        }
    }

    .mat-mdc-dialog-content {
        .page-title, .close {
            font-size: large !important;
        }
    }

    .icon-error {
        bottom: 25px !important;
    }

    .ng2-tag-input {
        font-size: small !important;
    }

    .third-fixed-en {
        left: 150px !important;
    }

    .third-fixed-de {
        left: 140px !important;
    }

    .copy-default-key {
        img {
            width: 30px;
            height: 30px;
        }
    }

}

@media (max-height: 700px) {
    .scroll {
        height: 37rem !important;
    }
}
