@import './variables';

@media (max-width: 1900px) {

    .app-table {
        margin: 0% !important;
        padding: 0% !important;
        // height: 20rem !important;
    }
    * {
        table {
            font-size: small !important;
            th {
                font-size: small !important;
            }
            .btn-action {
                // display: flex;
                width: 40px;
                height: 40px;
                font-size: small;
                align-items: center;
                justify-content: center;
                align-content: center;
            }
            img {
                width: 20px;
                height: 20px;
            }
            * {
                .sorting :after, .sorting :before {
                    width: 11px;
                    height: 10px !important;
                    background-size: 9px !important;
                }
                span {
                    font-size: 0.8rem !important;
                }
                .badge {
                    span {
                        font-size: 0.6rem !important;
                    }
                }
            }

            .col-confirm {
                width: 30px !important;
                height: 30px !important;

                .fab {
                    width: 30px !important;
                    height: 30px !important;
                    font-size: larger !important;
                    line-height: 30px !important;
                }
            }

            .actions {
                display: flex;
                flex-direction: row;
            }

            .td-booking-keys {
                padding: 0.2rem 15px 0.2rem 2rem !important;
            }
        }
    }

    .pagination {
        * {
            font-size: 0.9rem !important;
        }
    }

    .top-title {
        margin: 2% 0% !important;
        padding: 0% !important;
    }

    .page-title {
        font-size: x-large !important;
    }

    .nav-item {
        width: 0;
        span {
            font-size: small !important;
        }
        .side-menu-icon {
            width: 14px;
            height: 14px;
        }
    }

    .content {
        padding: 10px 0 20px 0
    }

    .content-opened {
        width: calc(100vw - #{$sidebar-min-width} - #{$sidebar-scroll});
    }

    .content-collapsed {
        width: calc(100vw - #{$sidebar-opened} - #{$sidebar-scroll});
    }

    .sidebar-opened {
        max-width: $sidebar-opened !important;

        .profile-element {
            width: 100px;
            height: 100px;
        }
    }

    .sidebar  {

        .company-info {
            * {
                font-size: 1rem !important;
            }
            .label-email {
                font-size: medium !important;
            }
        }
        .menu-item {
            min-width: 100% !important;
            * {
                font-size: small !important;
            }
            .material-icons {
                font-size: 1rem !important;
            }
            .notification-bell-container {
                .custom-badge {
                    width: 20px;
                    height: 20px;
                    font-size: x-small !important;
                }
                .animate-notification {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .invite-link-dropdown-title, .invite-link-field {
        font-size: medium !important;
    }

    .header {
        .user-info {
            display: flex !important;
            align-items: center;
            .user-image {
                width: 30px;
                height: 30px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                padding: 0px !important;
                img {
                    width: 15px;
                }
            }
            .dropdown {
                margin-top: 0px !important;
                * {
                    font-size: small !important;
                }

                .btn-logout {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .open-tasks {
        max-height: 50vh;
        .card-task {
            .card-left-side {
                font-size: small !important;
            }

            .card-right-side {
                font-size: small !important;
            }

            .card-right-text {
                padding-bottom: 0% !important;
            }
        }
    }

    .header-collapsed {
        .page-description {
            font-size: small !important;
            padding-left: 0 !important;
        }
    }

    span, label {
        font-size: small !important;
    }

    .page-description {
        font-size: 1rem !important;
    }

    input, option, .ng-option, .ng-select, .mat-calendar-body, .mat-calendar-table-header, .mat-tooltip {
        font-size: small !important;
    }

    .form-text {
        font-size: small !important;
    }

    .switch-medium {
        height: 20px !important;
        min-width: 35px !important;
    }

    .switch-medium small {
        width: 19px !important;
        height: 19px !important;
    }

    .bubble-info-task-to-do {
        span {
            text-transform: uppercase;
            font-size: 10px;
            color: red;
            letter-spacing: 0;
            font-weight: normal;
        }
        i {
            font-size: 20px;
            color: red;
            font-weight: normal;
            padding-left: 2px;
            margin-left: 12px;
        }
    }

    .bubble-info-task-to-do:before {
        left: 15% !important;
    }

    .auth-full {
        .header {
            .logotipo-full {
                .header-icons {
                    img {
                        width: 45px;
                    }
                }
            }
        }

        .validator {
            .header {
                h1 {
                    font-size: medium;
                }
                h2 {
                    font-size: small;
                }
                margin-top: 0 !important;
            }
        }

        .form-login {
            max-width: 300px;
            margin: 0 auto;
            img {
                width: 30px !important;
            }

            .form-control {
                padding: 25px 42px 25px 50px;
                font-size: small !important;
            }

            .forgot-password {
                font-size: small !important;
            }

            .btn-login {
                margin-top: 10% !important;
            }
        }
    }

    .jumbotron {
        .search-off {
            font-size: medium !important;
            margin-left: 2%;
        }

        .display-4 {
            font-size: medium !important;
        }
    }

    app-toggle-drop {
        .current, .to-save, .expired, .future {
            font-size: small !important;
        }
    }

    .custom-drop {
        .confirm-toggle {
            min-width: 10px !important;
            font-size: small !important;
        }
    }

    .circle-modal {
        i {
            font-size: 35px !important;
        }
    }

    .mat-mdc-dialog-content {
        .page-title, .close {
            font-size: large !important;
        }
    }

    .ng2-tag-input {
        font-size: small !important;
    }

    .copy-default-key {
        img {
            width: 30px;
            height: 30px;
        }
    }
}

@media (max-height: 900px) {
    .scroll {
        height: 25rem !important;
    }

    .btn-close {
        top: 0 !important;
        bottom: auto !important;
    }
}

.tooltip-text {
    --balloon-font-size: small;
}
