@import './variables';

.mdc-dialog__surface {
    position: inherit !important;
    box-shadow: none !important;
    padding: 24px 0 24px 0 !important;
}

.circle-modal {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -87px;
    i {
        color: white;
        font-size: 40px;
    }
}

.principal-color {
    background-color: $principal-color;
}
.secundary-color {
    background-color: $secundary-color;
}
.warning-color {
    background-color: $warning-intense-color;
}
.success-color {
    background-color: $success-color;
}
.danger-color {
    background-color: $danger-light-color;
}
.grey-color {
    background-color: $custom-grey-color;
}

//Page description
$page-description-color: #394551;

//Tabs
$tab-not-selected: #8F9CB4;
$tab-active: #1276FF;


.full-modal > mat-dialog-container {
    padding: 0 !important;
}

mat-dialog-container {
    display: block;
    padding: 24px 45px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}

.curved-modal > mat-dialog-container {
    background-color: transparent;
    box-shadow: none;

    .mat-mdc-dialog-surface {
        max-height: 85vh;
        overflow-y: auto;
        padding-bottom: 0 !important;
        display: contents;
        .page-title {
            margin-top: 0 !important;
        }
    }

    .mat-mdc-dialog-actions {
        padding: 0 !important;
    }
}

.curved-modal > mat-dialog-container:before {
    content: "";
    width: 100px;
    border-radius: 50%;
    height: 100px;
    display: block;
    margin: -70px auto 0;
    box-shadow: 0 0 0 3000px white;
}

.curved-modal .mat-mdc-dialog-content {
    overflow: hidden !important;
}

.choose-importation-file-modal > mat-dialog-container {
    .mat-mdc-dialog-surface {
        overflow: hidden;
    }

    .mat-mdc-dialog-content {
        overflow: hidden;
    }
}

.view-import-data-modal > mat-dialog-container {
    padding: 0;

    .mat-mdc-dialog-surface {
        padding: 1rem !important;
    }
}

.modal-title {
    font-size: 1rem !important;
    text-transform: uppercase !important;
    color: $title-color;
    font-weight: bold !important;

    &:before {
        content: unset !important;
    }
}

.modal-subtitle {
    font-size: 1rem;
    color: black;
    font-family: Mulish, sans-serif;
}

.modal-video {
    .mdc-dialog__container {
        padding: 5px;
        overflow: hidden;
    }

    .cdk-overlay-pane {
        max-width: none !important;
        height: 95%;
    }
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .curved-modal > mat-dialog-container {
        .mat-mdc-dialog-surface {
            .mat-mdc-dialog-content {
                font-size: 14px !important;
            }
        }
    }

    .choose-importation-file-modal > mat-dialog-container {
        .mat-mdc-dialog-content {
            padding-top: 0 !important;
        }
    }
}
