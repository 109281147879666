@import '../../assets/scss/variables';

.btn-primary, .btn-warning, .btn-danger, .btn-success, .btn-secondary, .btn-outline-light, .btn-outline-primary {
    border-radius: 40px 40px 40px 40px;
    color: #fff;
    float: right;
    border: none;
    margin-bottom: 30px;
    padding: 10px 17px;
    min-width: 115px !important;
    text-align: center;
}

.btn-pulse-warning {
    transform: scale(1);
    animation: pulse-black 2s infinite;
    background-color: #e1e8ee;
    border-color: transparent !important;
    color: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: all .1s ease-in-out;

    &:focus, &:hover, &:active, &:active:focus {
        outline: none;
    }

    &:disabled {
        background-color: #e1e8ee;
        cursor: not-allowed;
    }

    i {
        margin-top: 7px;
    }
}

.btn-pulse-warning {
    transform: scale(1);
    animation: pulse-black 2s infinite;
    background-color: #e1e8ee;
    border-color: transparent !important;
    color: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: all .1s ease-in-out;

    &:focus, &:hover, &:active, &:active:focus {
        outline: none;
    }

    &:disabled {
        background-color: #e1e8ee;
        cursor: not-allowed;
    }

    i {
        margin-top: 7px;
    }
}

.btn-outline-light {
    border: 1px solid $principal-color;
    color: $principal-color;
}

.btn-outline-light:hover {
    border: 1px solid $principal-color;
    color: $principal-color;
}

.btn-primary {
    background: $principal-color;
}

.btn-outline-primary {
    color: $principal-color;
    border: 1px solid $principal-color;
    &:hover {
        outline: none;
        box-shadow: none;
        background-color: $principal-color;
        color: white;
    }
    &:focus, &:active, &:active:focus {
        background-color: white !important;
        color: $principal-color !important;
    }
}

.btn-success {
    background: $success-color;
}

.btn-secondary {
    background: $custom-grey-color;
}

.btn-danger {
    background: $danger-color;
}

.btn-action {
    background-color: #e1e8ee;
    border-color: transparent !important;
    box-shadow: none !important;
    color: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: all .1s ease-in-out;

    &:focus, &:hover, &:active, &:active:focus {
        outline: none;
        box-shadow: none;
        background-color: $principal-color;
    }

    &:disabled {
        background-color: #e1e8ee;
        cursor: not-allowed;
    }

    i {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: larger !important;
    }
}

.btn-muted {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
    color: $principal-color;
}

.btn-muted:focus, .btn-muted:hover, .btn-muted:active, .btn-muted:active:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.rounded {
    border-radius: 20px !important;
}

.btn-action:disabled {
    opacity: 0.6;
}
