
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import 'assets/scss/variables';
@import 'assets/scss/buttons';
@import 'assets/scss/fonts';
@import 'assets/scss/support';
@import 'assets/scss/theme';
@import 'assets/scss/ui-switch';
@import 'assets/scss/checkbox';
//@import 'node_modules/ngx-ui-switch/ui-switch.component.scss';
@import 'assets/scss/ngx-editor';
@import 'assets/scss/mat-stepper';
@import 'assets/scss/side-menu';
@import 'assets/scss/animations';
@import 'assets/scss/_w_1366';
@import 'assets/scss/_w_1980';
@import "node_modules/balloon-css/src/balloon";
@import 'assets/scss/modal';
@import 'assets/scss/custom-spinners';

@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import 'assets/scss/datatable-theme';

.popover li:hover {
    background: #FFF !important;
}

.space-content {
    padding-right: 40px !important;
    padding-left: 40px !important;
}

@import 'ngx-toastr/toastr';
